// // BookDemoModal.js
// import React from 'react';
// import Modal from 'react-modal';
// import './reactModal.css';

// const BookDemoModal = ({ isOpen, onRequestClose }) => {
//   return (
//     <Modal
//       isOpen={isOpen}
//       onRequestClose={onRequestClose}
//       className="book-demo-modal"
//       overlayClassName="book-demo-overlay"
//       ariaHideApp={false}
//     >
//       <div className="modal-header">
//         <h2>Book Demo</h2>
//         <button onClick={onRequestClose} className="close-button">×</button>
//       </div>
//       <form className="modal-form">
//         <label>
//           Name
//           <input type="text" name="name" />
//         </label>
//         <label>
//           Organization Mail Id
//           <input type="email" name="email" />
//         </label>
//         <label>
//           Date
//           <input type="date" name="date" placeholder='' />
//         </label>
//         <label>
//           Time
//           <input type="time" name="time" placeholder='' />
//         </label>
//         <button type="submit" className="submit-button">Book Demo</button>
//       </form>
//     </Modal>
//   );
// };

// export default BookDemoModal;

// // BookDemoModal.js
// import React, { useState } from "react";
// import Modal from "react-modal";
// import "./reactModal.css";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const BookDemoModal = ({ isOpen, onRequestClose }) => {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [date, setDate] = useState("");
//   const [time, setTime] = useState("");
//   const [emailError, setEmailError] = useState("");
//   const [formError, setFormError] = useState("");

//   const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Validate form fields
//     let isValid = true;
//     setFormError("");
//     if (!name || !email || !date || !time) {
//       setFormError("All fields are required.");
//       isValid = false;
//     }
//     if (!isValidEmail(email)) {
//       setEmailError("Invalid email format");
//       isValid = false;
//     } else {
//       setEmailError("");
//     }

//     if (!isValid) {
//       return;
//     }

//     const payload = {
//       name: name,
//       email: email,
//       date: date,
//       time: time,
//       from: "Wikiworks Demo Booking",
//       to: "sales@wikiworks.com",
//     };

//     try {
//       const response = await fetch(
//         "https://demo.wikiworks.in/api/ampliworks/sendEmail",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             "Access-Control-Allow-Origin": "*",
//           },
//           body: JSON.stringify(payload),
//         }
//       );

//       const data = await response.json();
//       console.log("Response data: ", data);

//       if (response.ok) {
//         toast.success("Demo booked successfully! We will contact you soon.", {
//           position: "top-center",
//           autoClose: 3000,
//           style: { marginTop: "100px" },
//         });
//         // Clear the form fields
//         setName("");
//         setEmail("");
//         setDate("");
//         setTime("");
//         onRequestClose(); // Close the modal on success
//       } else {
//         toast.error("Failed to book demo. Please try again later.", {
//           position: "top-center",
//           autoClose: 3000,
//           style: { marginTop: "100px" },
//         });
//       }
//     } catch (error) {
//       console.error("Error: ", error);
//       toast.error("An error occurred while booking the demo.", {
//         position: "top-center",
//         autoClose: 3000,
//         style: { marginTop: "100px" },
//       });
//     }
//   };

//   return (
//     <Modal
//       isOpen={isOpen}
//       onRequestClose={onRequestClose}
//       className="book-demo-modal"
//       overlayClassName="book-demo-overlay"
//       ariaHideApp={false}
//     >
//       <div className="modal-header">
//         <h2>Book Demo</h2>
//         <button onClick={onRequestClose} className="close-button">
//           ×
//         </button>
//       </div>
//       <form className="modal-form" onSubmit={handleSubmit}>
//         <label>
//           Name
//           <input
//             type="text"
//             name="name"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//           />
//         </label>
//         <label>
//           Organization Mail Id
//           <input
//             type="email"
//             name="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//           />
//           {emailError && <p className="errorText">{emailError}</p>}
//         </label>
//         <label>
//           Date
//           <input
//             type="date"
//             name="date"
//             value={date}
//             onChange={(e) => setDate(e.target.value)}
//           />
//         </label>
//         <label>
//           Time
//           <input
//             type="time"
//             name="time"
//             value={time}
//             onChange={(e) => setTime(e.target.value)}
//           />
//         </label>
//         {formError && <p className="errorText">{formError}</p>}
//         <button type="submit" className="submit-button">
//           Submit
//         </button>
//       </form>
//       <ToastContainer position="top-left" style={{ marginTop: "20px" }} />
//     </Modal>
//   );
// };

// export default BookDemoModal;

import React, { useState } from "react";
import Modal from "react-modal";
import "./reactModal.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";

const BookDemoModal = ({ isOpen, onRequestClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loader state

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    let isValid = true;
    setFormError("");
    if (!name || !email || !date || !time) {
      setFormError("All fields are required.");
      isValid = false;
    }
    if (!isValidEmail(email)) {
      setEmailError("Invalid email format");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!isValid) {
      return;
    }

    const templateParams = {
      to_name: "Wikiworks Team", // Recipient
      from_name: name,
      message: `You have a new demo booking from ${name}.\n\nDetails:\n- Name: ${name}\n- Email: ${email}\n- Date: ${date}\n- Time: ${time}`,
    };

    try {
      setIsLoading(true); // Show loader
      await emailjs.send(
        "service_1e5xeqo", // Replace with your EmailJS Service ID
        "template_6ees2pr", // Replace with your EmailJS Template ID
        templateParams,
        "piFdp5IfrbeQZ9qA-" // Replace with your EmailJS User ID
      );

      toast.success("Demo booked successfully! We will contact you soon.", {
        position: "top-center",
        autoClose: 3000,
        style: { marginTop: "0px" },
      });

      // Clear the form fields and close the modal
      setName("");
      setEmail("");
      setDate("");
      setTime("");
      onRequestClose();
    } catch (error) {
      console.error("Error: ", error);
      toast.error("An error occurred while booking the demo.", {
        position: "top-center",
        autoClose: 3000,
        style: { marginTop: "0px" },
      });
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="book-demo-modal"
      overlayClassName="book-demo-overlay"
      ariaHideApp={false}
    >
      <div className="modal-header">
        <h2>Book Demo</h2>
        <button onClick={onRequestClose} className="close-button">
          ×
        </button>
      </div>
      <form className="modal-form" onSubmit={handleSubmit}>
        <label>
          Name
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </label>
        <label>
          Organization Mail Id
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {emailError && <p className="errorText">{emailError}</p>}
        </label>
        <label>
          Date
          <input
            type="date"
            name="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </label>
        <label>
          Time
          <input
            type="time"
            name="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            required
          />
        </label>
        {formError && <p className="errorText">{formError}</p>}
        <button type="submit" className="submit-button" disabled={isLoading}>
          {isLoading ? "Submitting..." : "Submit"}
        </button>
      </form>
      {isLoading && <div className="loader"></div>}
      <ToastContainer position="top-left" style={{ marginTop: "0px" }} />
    </Modal>
  );
};

export default BookDemoModal;
