// import React, { useState } from "react";
// import "./contact_us.css";
// import leftIcon from "../../img/Contact Us.png";
// import pr3 from "../../img/smallBorderL2.png";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import privacyPolicy from "../../docs/PrivacyPolicy_wikiworks.pdf";

// // import nodeoutlook from 'nodejs-nodemailer-outlook'

// const Contact_us = () => {
//   const [isChecked, setIsChecked] = useState(false);

//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [mobile, setMobile] = useState("");
//   const [message, setMessage] = useState("");
//   const [subject, setSubject] = useState("");

//   const [emailError, setEmailError] = useState("");
//   const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

//   const emailSubject = `${subject}`;
//   const emailBody = `Email From : ${name}\nEmail is : ${email}\n${message}`;

//   const handleSubmit = (e) => {
//     // e.preventDefault();

//     // Validate email
//     let isValid = true;
//     if (!isValidEmail(email)) {
//       setEmailError("Invalid email format");
//       isValid = false;
//     } else {
//       setEmailError("");
//     }

//     if (!isValid) {
//       return;
//     }

//     const payload = {
//       subject: emailSubject,
//       text: emailBody,
//       mobileNo: mobile,
//       from: "Wikiworks Notifications",
//       to: "sales@wikiworks.com",
//     };
//     // http://localhost:3000/api/wikiworks/sendEmail
//     // https://demo.wikiworks.in/api/ampliworks/sendEmail
//     fetch("https://demo.wikiworks.in/api/ampliworks/sendEmail", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "*",
//       },
//       body: JSON.stringify(payload),
//       mode: "cors",
//     })
//       .then((resp) => {
//         resp.json();
//       })
//       .then((data) => {
//         console.log("data is ", data);
//       })
//       .catch((error) => {
//         console.error("Error is : ", error);
//       });
//     setName("");
//     setEmail("");
//     setSubject("");
//     setMessage("");
//     setMobile("");
//     toast.success(
//       "Email Sent Successfully! We will be reaching out to you soon",
//       { position: "top-center", autoClose: 3000, style: { marginTop: "100px" } }
//     );
//   };

//   return (
//     <div className="contact-us-ext">
//       <div className="rHeader">Contact us</div>
//       <div className="subHeader">
//         Have any queries or feedback for us? Fillout the form below to contact
//         our team.
//       </div>

//       <div id="ContactUs" className="contactContainer">
//         <div className="leftContact">
//           <img style={{ width: "90%" }} src={leftIcon} alt="" />
//         </div>
//         <div className="rightContact">
//           {/* <img
//           style={{ width: "15%", objectFit: "contain", marginTop: "1rem" }}
//           src={pr3}
//           alt="pr2"
//         /> */}

//           <div className="formContainer">
//             <form
//               onSubmit={(e) => {
//                 e.preventDefault();
//               }}
//             >
//               <input
//                 type="text"
//                 placeholder="Name"
//                 value={name}
//                 onChange={(e) => {
//                   setName(e.target.value);
//                 }}
//               />
//               <input
//                 type="email"
//                 placeholder="E-mail"
//                 value={email}
//                 onChange={(e) => {
//                   setEmail(e.target.value);
//                 }}
//               />
//               {emailError && <p className="errorText">{emailError}</p>}

//               <input
//                 type="number"
//                 placeholder="Mobile Number"
//                 value={mobile}
//                 onChange={(e) => {
//                   setMobile(e.target.value);
//                 }}
//               />
//               <input
//                 type="text"
//                 placeholder="Subject"
//                 value={subject}
//                 onChange={(e) => {
//                   setSubject(e.target.value);
//                 }}
//               />
//               <textarea
//                 placeholder="Message"
//                 rows="4"
//                 value={message}
//                 onChange={(e) => {
//                   setMessage(e.target.value);
//                 }}
//               ></textarea>
//               {/* <div className="consent">
//               <input
//                 type="checkbox"
//                 id="privacyCheckbox"
//                 onChange={() => {
//                   setIsChecked(!isChecked);
//                 }}
//                 style={{ width: "5%" }}
//               />
//               <label htmlFor="privacyCheckbox">
//                 <span className="consentText">
//                   I have read the{" "}
//                   <a href={privacyPolicy} target="_blank">
//                     Privacy Policy
//                   </a>
//                   <a href="/privacyPolicy">Privacy Policy</a>
//                 </span>
//               </label>
//             </div> */}

//               <button
//                 onClick={() => {
//                   // if (!isChecked) {
//                   //   toast.warning(
//                   //     "Please fill out all required fields correctly and accept the terms"
//                   //   );
//                   // } else {
//                   //   handleSubmit();
//                   // }

//                   handleSubmit();
//                 }}
//                 type="submit"
//                 className="award-button-c"
//               >
//                 Get in Touch
//               </button>
//             </form>

//             {/* <form>
//                         <input type="text" placeholder='Name'  />
//                         <input type="email" placeholder='E-mail'   />
//                         <input type="text" placeholder='Subject'/>
//                         <textarea placeholder='Message' rows="4"  ></textarea>
//                         <div className="consent">
//                             <input type="checkbox" id='privacyCheckbox' onChange={() => { setIsChecked(!isChecked) }} style={{ width: '7%' }} />
//                             <label htmlFor="privacyCheckbox"> Add a label element and associate it with the checkbox
//                                 <span className="consentText">I have read the </span>{' '}
//                                 <a href="/">Privacy Policy</a>
//                             </label>
//                         </div>
//                         <button className={isChecked ? 'btnActive' : 'btn'}><div className='sendTxt' style={{ fontWeight: 'bold' }}  >SUBMIT</div></button>
//                     </form> */}
//           </div>
//         </div>
//         <ToastContainer position="top-left" style={{ marginTop: "100px" }} />
//       </div>
//     </div>
//   );
// };

// export default Contact_us;
// import React, { useState } from "react";
// import "./contact_us.css";
// import leftIcon from "../../img/Contact Us.png";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import emailjs from "@emailjs/browser";

// const Contact_us = () => {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [mobile, setMobile] = useState("");
//   const [message, setMessage] = useState("");
//   const [subject, setSubject] = useState("");
//   const [emailError, setEmailError] = useState("");

//   const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Email validation
//     let isValid = true;
//     if (!isValidEmail(email)) {
//         setEmailError("Invalid email format");
//         isValid = false;
//     } else {
//         setEmailError("");
//     }

//     if (!isValid) return;

//     // Include additional details in the message body
//     const formattedMessage = `
//       Hello {{to_name}},

//       You got a new message from {{from_name}}:

//       Name: ${name}
//       Email: ${email}
//       Mobile Number: ${mobile}
//       Subject: ${subject}

//       Message:
//       ${message}

//       Regards,
//       Your Website Contact Form
//     `;

//     const templateParams = {
//         to_name: "Team", // Replace with recipient name if required
//         from_name: name,
//         email, 
//         mobile, 
//         subject,
//         message: formattedMessage,
//     };

//     try {
//         await emailjs.send(
//             "service_1e5xeqo", // Replace with your EmailJS Service ID
//             "template_6ees2pr", // Replace with your EmailJS Template ID
//             templateParams,
//             "piFdp5IfrbeQZ9qA-" // Replace with your EmailJS User ID
//         );

//         toast.success(
//             "Email Sent Successfully! We will be reaching out to you soon",
//             { position: "top-center", autoClose: 3000, style: { marginTop: "0px" } }
//         );
//         setName("");
//         setEmail("");
//         setMobile("");
//         setMessage("");
//         setSubject("");
//     } catch (error) {
//         console.error("Error sending email:", error);
//         toast.error("Failed to send the email. Please try again later.", {
//             position: "top-center",
//             autoClose: 3000,
//             style: { marginTop: "0px" },
//         });
//     }
// };


//   return (
//     <div className="contact-us-ext">
//       <div className="rHeader">Contact us</div>
//       <div className="subHeader">
//         Have any queries or feedback for us? Fill out the form below to contact
//         our team.
//       </div>

//       <div id="ContactUs" className="contactContainer">
//         <div className="leftContact">
//           <img style={{ width: "90%" }} src={leftIcon} alt="" />
//         </div>
//         <div className="rightContact">
//           <div className="formContainer">
//             <form onSubmit={handleSubmit}>
//               <input
//                 type="text"
//                 placeholder="Name"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//               />
//               <input
//                 type="email"
//                 placeholder="E-mail"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//               {emailError && <p className="errorText">{emailError}</p>}

//               <input
//                 type="number"
//                 placeholder="Mobile Number"
//                 value={mobile}
//                 onChange={(e) => setMobile(e.target.value)}
//               />
//               <input
//                 type="text"
//                 placeholder="Subject"
//                 value={subject}
//                 onChange={(e) => setSubject(e.target.value)}
//               />
//               <textarea
//                 placeholder="Message"
//                 rows="4"
//                 value={message}
//                 onChange={(e) => setMessage(e.target.value)}
//               ></textarea>

//               <button type="submit" className="award-button-c">
//                 Get in Touch
//               </button>
//             </form>
//           </div>
//         </div>
//         <ToastContainer position="top-left" style={{ marginTop: "100px" }} />
//       </div>
//     </div>
//   );
// };

// export default Contact_us;


import React, { useState } from "react";
import "./contact_us.css";
import leftIcon from "../../img/Contact Us.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";

const Contact_us = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false); // Added loading state

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Email validation
    let isValid = true;
    if (!isValidEmail(email)) {
      setEmailError("Invalid email format");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!isValid) return;

    setLoading(true); // Start loader

    const formattedMessage = `
     
      Name: ${name}
      Email: ${email}
      Mobile Number: ${mobile}
      Subject: ${subject}

      Message:
      ${message}

      Regards,
      Your Website Contact Form
    `;

    const templateParams = {
      to_name: "Wikiworks Team",
      from_name: name,
      email,
      mobile,
      subject,
      message: formattedMessage,
    };

    try {
      await emailjs.send(
        "service_1e5xeqo", // Replace with your EmailJS Service ID
        "template_6ees2pr", // Replace with your EmailJS Template ID
        templateParams,
        "piFdp5IfrbeQZ9qA-" // Replace with your EmailJS User ID
      );

      toast.success(
        "Email Sent Successfully! We will be reaching out to you soon",
        { position: "top-center", autoClose: 3000, style: { marginTop: "0px" } }
      );
      setName("");
      setEmail("");
      setMobile("");
      setMessage("");
      setSubject("");
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Failed to send the email. Please try again later.", {
        position: "top-center",
        autoClose: 3000,
        style: { marginTop: "0px" },
      });
    } finally {
      setLoading(false); // Stop loader
    }
  };

  return (
    <div className="contact-us-ext">
      <div className="rHeader">Contact us</div>
      <div className="subHeader">
        Have any queries or feedback for us? Fill out the form below to contact
        our team.
      </div>

      <div id="ContactUs" className="contactContainer">
        <div className="leftContact">
          <img style={{ width: "90%" }} src={leftIcon} alt="" />
        </div>
        <div className="rightContact">
          <div className="formContainer">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="email"
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && <p className="errorText">{emailError}</p>}

              <input
                type="number"
                placeholder="Mobile Number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
              <input
                type="text"
                placeholder="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <textarea
                placeholder="Message"
                rows="4"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>

              <button
                type="submit"
                className="award-button-c"
                disabled={loading} // Disable button during loading
              >
                {loading ? (
                  <div className="loader"></div> // Loader animation
                ) : (
                  "Get in Touch"
                )}
              </button>
            </form>
          </div>
        </div>
        <ToastContainer position="top-left" style={{ marginTop: "100px" }} />
      </div>
    </div>
  );
};

export default Contact_us;
